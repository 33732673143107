var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('DialogDashboards',{attrs:{"dialog":_vm.dialog}}),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}}),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{staticClass:"pa-2"},[_c('v-col',{staticClass:"pa-1"},[_c('CardDashboard',{attrs:{"loading":_vm.carregando,"title":"Dados a exibir (%)"}},[(1 != 1)?[_vm._v(" 00.00%")]:[_vm._v("00.00%")]],2)],1),(_vm.usuario.idgrupo != 7)?_c('v-col',{staticClass:"pa-1"},[_c('CardDashboard',{attrs:{"loading":_vm.carregando,"title":"Dados a exibir"}},[( 1 == 1)?[_vm._v(" "+_vm._s(_vm._f("formataDinheiro")(10000))+" ")]:[_vm._v("31651")]],2)],1):_vm._e(),(_vm.usuario.idgrupo != 7)?_c('v-col',{staticClass:"pa-1"},[_c('CardDashboard',{attrs:{"loading":_vm.carregando,"title":"Dados a exibir"}},[( 1 == 1)?[_vm._v(" "+_vm._s(_vm._f("formataDinheiro")(10000))+" ")]:[_vm._v("31651")]],2)],1):_vm._e(),(_vm.usuario.idgrupo != 7)?_c('v-col',{staticClass:"pa-1"},[_c('CardDashboard',{attrs:{"loading":_vm.carregando,"title":"Dados a exibir"}},[( 1 == 1)?[_vm._v(" "+_vm._s(_vm._f("formataDinheiro")(30000))+" (5.00%) ")]:[_vm._v("31651")]],2)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',_vm._l((_vm.totais),function(total,i){return _c('v-expansion-panel',{key:i,attrs:{"active-class":"primary white--text rounded-0"}},[_c('v-expansion-panel-header',{staticClass:"py-2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"text-h6 text-truncate",attrs:{"cols":"auto","md":"5"}},[_vm._v("Pedidos de Compra - "+_vm._s(total.ano))]),_c('v-col',{staticClass:"d-none d-md-block"},[_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(!open)?_c('v-row',{staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center font-weight-bold text-truncate"},[_c('small',[_vm._v("A vista "+_vm._s(total.ano))]),_c('br'),_c('v-chip',{staticClass:"mt-1",attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(_vm._f("formataDinheiro")(total.valoravista))+" ("+_vm._s(total.percavista.toFixed(2))+"%)")])],1),_c('v-col',{staticClass:"text-center font-weight-bold text-truncate"},[_c('small',[_vm._v("A prazo "+_vm._s(total.ano))]),_c('br'),_c('v-chip',{staticClass:"mt-1",attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(_vm._f("formataDinheiro")(total.valoraprazo))+" ("+_vm._s(total.percaprazo.toFixed(2))+"%)")])],1),_c('v-col',{staticClass:"text-center font-weight-bold text-truncate"},[_c('small',[_vm._v("Geral "+_vm._s(total.ano))]),_c('br'),_c('v-chip',{staticClass:"mt-1",attrs:{"color":"primary","dark":"","small":""}},[_vm._v(_vm._s(_vm._f("formataDinheiro")(total.valorgeral)))])],1)],1):_vm._e()],1)],1)],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"px-0"},[(_vm.dadosmes)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"mobile-breakpoint":"0","dark":false,"dense":"","items-per-page":1000,"headers":[
                            {text: '', value: 'detalhe', class:'text-truncate pl-2 ', align: 'center', sortable:false},
                            {text: 'Mês', value: 'mesnome', class:'text-truncate pl-0', sortable:false},
                            {text: 'Valor a prazo', value: 'valoraprazo', class:'text-truncate'},
                            {text: '% a prazo', value: 'percaprazo', class:'text-truncate'},
                            {text: 'Valor a vista', value: 'valoravista', class:'text-truncate'},
                            {text: '% a vista', value: 'percavista', class:'text-truncate'},
                            {text: 'Valor Geral', value: 'valorgeral', class:'text-truncate', sortable:false} ],"items":_vm.dadosmes.filter(function (v) { return v.ano == total.ano; }).map(function (u) {
                            return {
                                mes: u.mes,
                                mesnome: u.mesnome,
                                valoraprazo: u.valoraprazo,
                                percaprazo: u.percaprazo,
                                valoravista: u.valoravista,
                                percavista: u.percavista,
                                valorgeral: u.valorgeral,
                            };
                        }),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                        var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center pl-2"},[_c('v-icon',{attrs:{"color":"primary","size":"18"},on:{"click":function($event){_vm.abrirDialog(
                                        _vm.dadosdias.filter(function (v){ return v.ano == total.ano && v.mes == item.mes; }),
                                        {
                                            dia: {nome: 'Dia', tipo: 'texto'},
                                            semananome: {nome: 'Dia da semana', tipo: 'texto', class: 'text-truncate'},
                                            valoraprazo: {nome: 'Valor a prazo', tipo: 'dinheiro', class: 'text-truncate'},
                                            percaprazo: {nome: '% a prazo', tipo: 'porcentagem', class: 'text-truncate text-center'},
                                            valoravista: {nome: 'Valor a vista', tipo: 'dinheiro'},
                                            percavista: {nome: '% a vista', tipo: 'porcentagem', class: 'text-truncate text-center'},
                                            valorgeral: {nome: 'Valor geral', tipo: 'dinheiro'},
                                        },
                                        ("" + (item.mesnome +' '+ total.ano))
                                    )}}},[_vm._v("mdi-magnify-plus-outline ")])],1),_c('td',{staticClass:"text-truncate pl-0"},[_vm._v(" "+_vm._s(item.mesnome)+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("formataDinheiro")(item.valoraprazo)))]),_c('td',[_vm._v(_vm._s(item.percaprazo.toFixed(2))+"%")]),_c('td',[_vm._v(_vm._s(_vm._f("formataDinheiro")(item.valoravista)))]),_c('td',[_vm._v(_vm._s(item.percavista.toFixed(2))+"%")]),_c('td',[_vm._v(_vm._s(_vm._f("formataDinheiro")(item.valorgeral)))])])]}},{key:"foot",fn:function(){return [_c('tr',{staticClass:"font-weight-bold blue-grey lighten-4"},[_c('td',{staticClass:"text-center",attrs:{"height":"30px"}},[_c('small',[_vm._v("Total")])]),_c('td'),_c('td',{staticClass:"pl-4"},[_c('small',[_vm._v(_vm._s(_vm._f("formataDinheiro")(_vm.totais.filter(function (v) { return v.ano == total.ano; }).map(function (v) { return v.valoraprazo; }))))])]),_c('td',{staticClass:"pl-4"},[_c('small',[_vm._v(_vm._s((_vm.totais.filter(function (v) { return v.ano == total.ano; }).map(function (v) { return v.percaprazo; })[0]).toFixed(2))+"%")])]),_c('td',{staticClass:"pl-4"},[_c('small',[_vm._v(_vm._s(_vm._f("formataDinheiro")(_vm.totais.filter(function (v) { return v.ano == total.ano; }).map(function (v) { return v.valoravista; }))))])]),_c('td',{staticClass:"pl-4"},[_c('small',[_vm._v(_vm._s((_vm.totais.filter(function (v) { return v.ano == total.ano; }).map(function (v) { return v.percavista; })[0]).toFixed(2))+"%")])]),_c('td',{staticClass:"pl-4"},[_c('small',[_vm._v(_vm._s(_vm._f("formataDinheiro")(_vm.totais.filter(function (v) { return v.ano == total.ano; }).map(function (v) { return v.valorgeral; }))))])])])]},proxy:true}],null,true)}):_vm._e()],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }