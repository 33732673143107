<template>
	<v-dialog v-model="dialog.status" scrollable>
		<v-card color="primary" flat class="border-dialog-card">
			<v-card-title class="text-h5 white--text">
				<span class="mx-auto">{{dialog.title}}</span>
			</v-card-title>
			<v-card-text class="pa-0">
				<v-simple-table>
					<thead>
						<tr>
							<th
								:class="dialog.header[i].class || ''"
								v-for="(h, i) in dialog.header"
								:key="`th_${i}`"
							>{{h.nome}}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(dado,i) in lista" :key="`tr_${i}`">
							<td v-for="(d,i) in dado" :key="`td_${i}`">
								<div v-if="dialog.header[i].tipo == 'texto'" :class="dialog.header[i].class || ''">{{d}}</div>
								<div
									v-if="dialog.header[i].tipo == 'dinheiro'"
									:class="dialog.header[i].class || ''"
								>{{d | formataDinheiro}}</div>
								<div v-if="dialog.header[i].tipo == 'porcentagem'" :class="dialog.header[i].class || ''">
									<template v-if="isNaN(parseFloat(d))">{{(0).toFixed(2)}}</template>
									<template v-else>{{parseFloat(d).toFixed(2)}}</template> %
								</div>
								<div
									v-if="dialog.header[i].tipo == 'decimal'"
									:class="dialog.header[i].class || ''"
								>{{parseFloat(d).toFixed(dialog.header[i].casas)}}</div>
								<div
									v-if="dialog.header[i].tipo == 'contato'"
									:class="dialog.header[i].class || ''"
								>{{d | formataContato}}</div>
								<div
									v-if="dialog.header[i].tipo == 'cep'"
									:class="dialog.header[i].class || ''"
								>{{d | formataCep}}</div>
								<div
									v-if="dialog.header[i].tipo == 'cpf'"
									:class="dialog.header[i].class || ''"
								>{{d | formataCpf}}</div>
								<div v-if="dialog.header[i].tipo == 'link'" :class="dialog.header[i].class || ''">
									<v-btn small color="primary" outlined block :to="`${dialog.header[i].link}${d}`">{{d}}</v-btn>
								</div>
								<div v-if="dialog.header[i].tipo == 'boolean'" :class="dialog.header[i].class || ''">
									<div v-if="d && d != 'F' && d != 'f'">
										<v-chip small color="success" class="font-weight-bold">{{dialog.header[i].true}}</v-chip>
									</div>
									<div v-else>
										<v-chip dark color="red" small class="font-weight-bold">{{dialog.header[i].false}}</v-chip>
									</div>
								</div>
								<div v-if="dialog.header[i].tipo == 'objeto'" :class="dialog.header[i].class || ''">
									<span v-for="(c,j) in dialog.header[i].campos" :key="`i_${j}`">
										{{d[c]}}
										<template
											v-if="dialog.header[i].campos.length - 1 != j"
										>{{dialog.header[i].divisor}}</template>
									</span>
								</div>
								<div v-if="dialog.header[i].tipo == 'condicionais'" :class="dialog.header[i].class || ''">
									<v-chip
										dark
										:color="dialog.header[i].condicionais.filter(v => v.condicao == d).map(v => v.cor)[0]"
										small
										class="font-weight-bold"
									>{{d}}</v-chip>
								</div>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="white" class="px-10" elevation="0" text @click="dialog.status = false">Fechar</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "DialogDashboards",
	props: ["dialog"],
	computed: {
		lista() {
			return this.dialog.dados.map((v) => {
				let item = {};
				Object.keys(this.dialog.header).forEach((h) => {
					v[h] ? (item[h] = v[h]) : (item[h] = "");
				});
				return item;
			});
		},
	},
};
</script>

<style>
* > div::-webkit-scrollbar {
	width: 10px;
}

* > div::-webkit-scrollbar-track {
	background: #023449;
}

* > div::-webkit-scrollbar-thumb {
	background-color: #ffffff;
	border-radius: 4px;
	border: 2px solid #023449;
}

.border-dialog-card {
	border: 2px solid #023449;
	border-right: 0px;
}
</style>