<template>
    <v-row>
        <DialogDashboards :dialog="dialog" />
        <v-col cols="12" class="pa-0">
            <!--            <v-card>-->
            <!--                <v-card-text>-->
            <!--                    <v-form>-->
            <!--                        <v-row class="mb-n10">-->
            <!--                            <v-col class="px-1 pl-md-1 pr-lg-3 d-flex">-->
            <!--                                <v-col class="mt-n3 mb-3">-->
            <!--                                    <InputDatePicker-->
            <!--                                        :disabled="carregando"-->
            <!--                                        v-model="busca.dtinicio"-->
            <!--                                        label="Data inicial"-->
            <!--                                        :outlined="true"-->
            <!--                                        :dense="true"-->
            <!--                                    />-->
            <!--                                </v-col>-->
            <!--                                <v-col class="mt-n3 mb-3 pl-0">-->
            <!--                                    <InputDatePicker-->
            <!--                                        :disabled="carregando"-->
            <!--                                        v-model="busca.dtfim"-->
            <!--                                        label="Data final"-->
            <!--                                        :outlined="true"-->
            <!--                                        :dense="true"-->
            <!--                                    />-->
            <!--                                </v-col>-->
            <!--                                <v-col class="mt-n3 mb-3 px-0 d-none d-md-block">-->
            <!--                                    <v-select-->
            <!--                                        clearable-->
            <!--                                        :disabled="carregando"-->
            <!--                                        label="Tipo pagamento"-->
            <!--                                        hide-details-->
            <!--                                        outlined-->
            <!--                                        dense-->
            <!--                                        :items="['À vista', 'A prazo']"-->
            <!--                                        v-model="busca.tipopagamento"-->
            <!--                                    ></v-select>-->
            <!--                                </v-col>-->
            <!--                                <v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">-->
            <!--                                    <v-sheet class="text-center" height="100%">-->
            <!--                                        <v-row class="pa-3">-->
            <!--                                            <v-col class="mt-n3 mb-3 px-0">-->
            <!--                                                <v-select-->
            <!--                                                    clearable-->
            <!--                                                    :disabled="carregando"-->
            <!--                                                    label="Tipo pagamento"-->
            <!--                                                    hide-details-->
            <!--                                                    outlined-->
            <!--                                                    dense-->
            <!--                                                    :items="['À vista', 'A prazo']"-->
            <!--                                                    v-model="busca.tipopagamento"-->
            <!--                                                ></v-select>-->
            <!--                                            </v-col>-->
            <!--                                            <v-col cols="6">-->
            <!--                                                <v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>-->
            <!--                                            </v-col>-->
            <!--                                            <v-col cols="6">-->
            <!--                                                <v-btn text class="mt-n3" color="primary" @click="get(), sheet = !sheet">Filtrar</v-btn>-->
            <!--                                            </v-col>-->
            <!--                                        </v-row>-->
            <!--                                    </v-sheet>-->
            <!--                                </v-bottom-sheet>-->
            <!--                                <v-btn-->
            <!--                                    :disabled="carregando"-->
            <!--                                    class="ml-2 mt-1"-->
            <!--                                    color="primary"-->
            <!--                                    elevation="0"-->
            <!--                                    fab-->
            <!--                                    x-small-->
            <!--                                    @click="get()"-->
            <!--                                >-->
            <!--                                    <v-icon>mdi-magnify</v-icon>-->
            <!--                                </v-btn>-->
            <!--                            </v-col>-->
            <!--                        </v-row>-->
            <!--                    </v-form>-->
            <!--                </v-card-text>-->
            <!--            </v-card>-->
        </v-col>
        <v-col cols="12" class="pa-0">
            <!--            <v-card color="primary" class="white&#45;&#45;text">-->
            <!--                <v-progress-linear indeterminate absolute :active="carregando" />-->
            <!--                <v-card-title class="text-truncate">Compras - Controle de Pedidos</v-card-title>-->
            <!--                <v-card-text v-if="carregando">-->
            <!--                    <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>-->
            <!--                </v-card-text>-->
            <!--                <v-card-text v-else>-->
            <!--                    <v-data-table-->
            <!--                        mobile-breakpoint="0"-->
            <!--                        v-if="dados"-->
            <!--                        :dark="false"-->
            <!--                        dense-->
            <!--                        :items-per-page="1000"-->
            <!--                        :headers="[-->
            <!--                            {text: 'Nº Pedido', value: 'idpedido', class:'text-truncate'},-->
            <!--                            {text: 'Fornecedor', value: 'idfornecedor', class:'text-truncate pl-0'},-->
            <!--                            {text: 'Pagamento', value: 'tipopagamento', class:'text-truncate', sortable:false},-->
            <!--                            {text: 'Valor', value: 'valorpedido', class:'text-truncate'},-->
            <!--                            {text: 'Data Pedido', value: 'dtentrega', class:'text-truncate', sortable:false},-->
            <!--                            {text: 'Data Entregue', value: 'dtentrega', class:'text-truncate', sortable:false},-->
            <!--                            {text: 'Status Pedido', value: 'statuspedido', align:'center', class:'text-truncate pl-0', sortable:false},-->
            <!--                            {text: 'Status Entrega', value: 'statusentrega', align:'center', class:'text-truncate pl-0', sortable:false},-->
            <!--                            {text: 'Cancelado', value: 'flagcancelado', class:'text-truncate', align:'center pl-0', sortable:false},-->
            <!--                        ]"-->
            <!--                        :items="dados.map((u) => {-->
            <!--                            return {-->
            <!--                                idpedido: u.idpedido,-->
            <!--                                idfornecedor: u.idfornecedor,-->
            <!--                                fornecedor: u.fornecedor,-->
            <!--                                dtpedido: u.dtpedido,-->
            <!--                                dtpreventrega: u.dtpreventrega,-->
            <!--                                dtentrega: u.dtentrega,-->
            <!--                                statuspedido: u.statuspedido,-->
            <!--                                statusentrega: u.statusentrega,-->
            <!--                                tipopagamento: u.tipopagamento,-->
            <!--                                valorpedido: u.valorpedido,-->
            <!--                                flagcancelado: u.flagcancelado,-->
            <!--                            };-->
            <!--                        })"-->
            <!--                        hide-default-footer-->
            <!--                        class="elevation-0"-->
            <!--                    >-->
            <!--                        <template v-slot:item="{ item }">-->
            <!--                            <tr>-->
            <!--                                <td>{{ item.idpedido }}</td>-->
            <!--                                <td class="text-truncate pl-0" style="max-width: 400px">-->
            <!--                                    {{ item.idfornecedor }} - {{ item.fornecedor }}<br/>-->
            <!--                                </td>-->
            <!--                                <td >{{ item.tipopagamento }}</td>-->
            <!--                                <td >{{ item.valorpedido | formataDinheiro}}</td>-->
            <!--                                <td class="text-truncate">-->
            <!--                                    {{item.dtpedido }}-->
            <!--                                </td>-->
            <!--                                <td class="text-truncate">-->
            <!--                                    {{item.dtentrega }}-->
            <!--                                </td>-->
            <!--                                <td class="text-center pl-0">-->
            <!--                                    <v-chip small :class="item.statuspedido == 'Atendido' ? 'green white&#45;&#45;text' : 'red white&#45;&#45;text'">{{ item.statuspedido }}</v-chip>-->
            <!--                                </td>-->
            <!--                                <td class="text-center pl-0">-->
            <!--                                    <v-chip small :class="item.statusentrega == 'Entregue no prazo' ? 'green white&#45;&#45;text' : 'red white&#45;&#45;text'">{{ item.statusentrega }}</v-chip>-->
            <!--                                </td>-->
            <!--                                <td class="text-center pl-0">-->
            <!--                                    <v-chip v-if="item.flagcancelado" small color="red" dark>-->
            <!--                                        <v-icon left small>mdi-close-circle</v-icon> Cancelado-->
            <!--                                    </v-chip>-->
            <!--                                </td>-->
            <!--                            </tr>-->
            <!--                        </template>-->
            <!--                        <template v-slot:foot>-->
            <!--                            <tr class="font-weight-bold blue-grey lighten-4">-->
            <!--                                <td class="pl-4" height="30px">Total</td>-->
            <!--                                <td>{{dados.total}} pedidos </td>-->
            <!--                                <td></td>-->
            <!--                                <td class="pl-4">{{dados.map(v => v.valorpedido).reduce((a,v) => a + v) | formataDinheiro}}</td>-->
            <!--                                <td></td>-->
            <!--                                <td></td>-->
            <!--                                <td></td>-->
            <!--                                <td></td>-->
            <!--                                <td></td>-->
            <!--                            </tr>-->
            <!--                        </template>-->
            <!--                    </v-data-table>-->
            <!--                </v-card-text>-->
            <!--            </v-card>-->
        </v-col>
        <v-col cols="12" md="12">
            <v-row class="pa-2">
                <v-col class="pa-1">
                    <CardDashboard :loading="carregando" title="Dados a exibir (%)">
                        <template
                            v-if="1 != 1"
                        > 00.00%</template>
                        <template v-else>00.00%</template>
                    </CardDashboard>
                </v-col>
                <v-col class="pa-1" v-if="usuario.idgrupo != 7">
                    <CardDashboard :loading="carregando" title="Dados a exibir">
                        <template v-if=" 1 == 1">
                            {{ 10000 | formataDinheiro }}
                        </template>
                        <template v-else>31651</template>
                    </CardDashboard>
                </v-col>
                <v-col class="pa-1" v-if="usuario.idgrupo != 7">
                    <CardDashboard :loading="carregando" title="Dados a exibir">
                        <template v-if=" 1 == 1">
                            {{ 10000 | formataDinheiro }}
                        </template>
                        <template v-else>31651</template>
                    </CardDashboard>
                </v-col>
                <v-col class="pa-1" v-if="usuario.idgrupo != 7">
                    <CardDashboard :loading="carregando" title="Dados a exibir">
                        <template v-if=" 1 == 1">
                            {{ 30000|formataDinheiro }}
                            (5.00%)
                        </template>
                        <template v-else>31651</template>
                    </CardDashboard>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-expansion-panels>
                <v-expansion-panel
                    v-for="(total, i) in totais" :key="i"
                    active-class="primary white--text rounded-0">
                    <v-expansion-panel-header class="py-2" v-slot="{ open }">
                        <v-row no-gutters align="center">
                            <v-col cols="auto" md="5" class="text-h6 text-truncate">Pedidos de Compra - {{ total.ano }}</v-col>
                            <v-col class="d-none d-md-block">
                                <v-fade-transition hide-on-leave>
                                    <v-row v-if="!open" no-gutters style="width: 100%">
                                        <v-col class="text-center font-weight-bold text-truncate">
                                            <small>A vista {{ total.ano }}</small><br />
                                            <v-chip color="primary" small class="mt-1">{{ total.valoravista | formataDinheiro}} ({{total.percavista.toFixed(2)}}%)</v-chip>
                                        </v-col>
                                        <v-col class="text-center font-weight-bold text-truncate">
                                            <small>A prazo {{ total.ano }}</small><br />
                                            <v-chip color="primary" small class="mt-1">{{ total.valoraprazo | formataDinheiro}} ({{total.percaprazo.toFixed(2)}}%)</v-chip>
                                        </v-col>
                                        <v-col class="text-center font-weight-bold text-truncate">
                                            <small>Geral {{ total.ano }}</small><br />
                                            <v-chip color="primary" dark small class="mt-1">{{ total.valorgeral | formataDinheiro}}</v-chip>
                                        </v-col>
                                    </v-row>
                                </v-fade-transition>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="px-0">
                        <v-data-table
                            mobile-breakpoint="0"
                            :dark="false"
                            v-if="dadosmes"
                            dense
                            :items-per-page="1000"
                            :headers="[
                                {text: '', value: 'detalhe', class:'text-truncate pl-2 ', align: 'center', sortable:false},
                                {text: 'Mês', value: 'mesnome', class:'text-truncate pl-0', sortable:false},
                                {text: 'Valor a prazo', value: 'valoraprazo', class:'text-truncate'},
                                {text: '% a prazo', value: 'percaprazo', class:'text-truncate'},
                                {text: 'Valor a vista', value: 'valoravista', class:'text-truncate'},
                                {text: '% a vista', value: 'percavista', class:'text-truncate'},
                                {text: 'Valor Geral', value: 'valorgeral', class:'text-truncate', sortable:false},
                            ]"
                            :items="dadosmes.filter(v => v.ano == total.ano).map((u) => {
                                return {
                                    mes: u.mes,
                                    mesnome: u.mesnome,
                                    valoraprazo: u.valoraprazo,
                                    percaprazo: u.percaprazo,
                                    valoravista: u.valoravista,
                                    percavista: u.percavista,
                                    valorgeral: u.valorgeral,
                                };
                            })"
                            hide-default-footer
                            class="elevation-0"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-center pl-2">
                                        <v-icon
                                            @click="abrirDialog(
                                            dadosdias.filter(v=> v.ano == total.ano && v.mes == item.mes),
                                            {
                                                dia: {nome: 'Dia', tipo: 'texto'},
                                                semananome: {nome: 'Dia da semana', tipo: 'texto', class: 'text-truncate'},
                                                valoraprazo: {nome: 'Valor a prazo', tipo: 'dinheiro', class: 'text-truncate'},
                                                percaprazo: {nome: '% a prazo', tipo: 'porcentagem', class: 'text-truncate text-center'},
                                                valoravista: {nome: 'Valor a vista', tipo: 'dinheiro'},
                                                percavista: {nome: '% a vista', tipo: 'porcentagem', class: 'text-truncate text-center'},
                                                valorgeral: {nome: 'Valor geral', tipo: 'dinheiro'},
                                            },
                                            `${item.mesnome +' '+ total.ano }`
                                        )"
                                            color="primary" size="18">mdi-magnify-plus-outline
                                        </v-icon>
                                    </td>
                                    <td class="text-truncate pl-0">
                                        {{item.mesnome }}
                                    </td>
                                    <td >{{ item.valoraprazo | formataDinheiro}}</td>
                                    <td >{{ item.percaprazo.toFixed(2) }}%</td>
                                    <td >{{ item.valoravista | formataDinheiro}}</td>
                                    <td >{{ item.percavista.toFixed(2) }}%</td>
                                    <td >{{ item.valorgeral | formataDinheiro}}</td>
                                </tr>
                            </template>
                            <template v-slot:foot>
                                <tr class="font-weight-bold blue-grey lighten-4">
                                    <td class="text-center" height="30px"><small>Total</small></td>
                                    <td></td>
                                    <td class="pl-4"><small>{{totais.filter(v => v.ano == total.ano).map(v => v.valoraprazo) | formataDinheiro}}</small></td>
                                    <td class="pl-4"><small>{{(totais.filter(v => v.ano == total.ano).map(v => v.percaprazo)[0]).toFixed(2) }}%</small></td>
                                    <td class="pl-4"><small>{{totais.filter(v => v.ano == total.ano).map(v => v.valoravista) | formataDinheiro}}</small></td>
                                    <td class="pl-4"><small>{{(totais.filter(v => v.ano == total.ano).map(v => v.percavista)[0]).toFixed(2) }}%</small></td>
                                    <td class="pl-4"><small>{{totais.filter(v => v.ano == total.ano).map(v => v.valorgeral) | formataDinheiro}}</small></td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import axios from "axios";
import CardDashboard from "@/Components/Widgets/CardDashboard";
import DialogDashboards from "../Widgets/DialogDashboards";

export default {
    name: "DashboardFinanceiro",
    mixins: [mixinFilial],
    components: {
        CardDashboard,
        DialogDashboards
    },
    data: () => ({
        dialog: { status: false, dados: [], header: {} },
        dadosmes: [
            // { ano:2022, mes: '01', mesname: 'Janeiro', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', mesname: 'Fevereiro', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '03', mesname: 'Março', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '04', mesname: 'Abril', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '05', mesname: 'Maio', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '06', mesname: 'Junho', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '07', mesname: 'Julho', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '08', mesname: 'Agosto', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '09', mesname: 'Setembro', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '10', mesname: 'Outrubro', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '11', mesname: 'Novembro', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2022, mes: '12', mesname: 'Dezembro', valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '01', mesname: 'Janeiro', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2021, mes: '02', mesname: 'Fevereiro', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '03', mesname: 'Março', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '04', mesname: 'Abril', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '05', mesname: 'Maio', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '06', mesname: 'Junho', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '07', mesname: 'Julho', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '08', mesname: 'Agosto', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '09', mesname: 'Setembro', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '10', mesname: 'Outrubro', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '11', mesname: 'Novembro', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2021, mes: '12', mesname: 'Dezembro', valorprazo : 21000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '01', mesname: 'Janeiro', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2020, mes: '02', mesname: 'Fevereiro', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '03', mesname: 'Março', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '04', mesname: 'Abril', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '05', mesname: 'Maio', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '06', mesname: 'Junho', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '07', mesname: 'Julho', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '08', mesname: 'Agosto', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '09', mesname: 'Setembro', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '10', mesname: 'Outrubro', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '11', mesname: 'Novembro', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2020, mes: '12', mesname: 'Dezembro', valorprazo : 20000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '01', mesname: 'Janeiro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2019, mes: '02', mesname: 'Fevereiro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '03', mesname: 'Março', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '04', mesname: 'Abril', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '05', mesname: 'Maio', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '06', mesname: 'Junho', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '07', mesname: 'Julho', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '08', mesname: 'Agosto', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '09', mesname: 'Setembro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '10', mesname: 'Outrubro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '11', mesname: 'Novembro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2019, mes: '12', mesname: 'Dezembro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '01', mesname: 'Janeiro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2018, mes: '02', mesname: 'Fevereiro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '03', mesname: 'Março', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '04', mesname: 'Abril', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '05', mesname: 'Maio', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '06', mesname: 'Junho', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '07', mesname: 'Julho', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '08', mesname: 'Agosto', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '09', mesname: 'Setembro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '10', mesname: 'Outrubro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '11', mesname: 'Novembro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
            // { ano:2018, mes: '12', mesname: 'Dezembro', valorprazo : 19000.00, valorvista : 2000.00, perprazo: 20, pervista: 15, valorgeral: 3000.00 },
        ],
        dadosdias: [
            // { ano:2022, mes: '01', dia:1,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:2,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:3,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:4,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:5,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:6,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:7,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:9,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:10, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:11, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:12, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:13, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:14, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:16, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:17, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:18, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:19, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:20, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:21, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:23, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:24, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:25, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:26, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:27, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:28, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:30, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '01', dia:31, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:1,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:2,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:3,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:4,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:5,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:6,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:7,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:8,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:9,  valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:10, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:11, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:12, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:13, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:14, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:15, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:16, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:17, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:18, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:19, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:20, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:21, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:22, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:23, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:24, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:25, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:26, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:27, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:28, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:29, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:30, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
            // { ano:2022, mes: '02', dia:31, valorprazo : 22000.00, valorvista : 2000.00, perprazo: 20, pervista: 15 , valorgeral: 3000.00},
        ],
        totais:[
            // { ano:2022, valorprazo : 6000.00, valorvista : 3000.00, perprazo: 20, pervista: 15 , valorgeral: 9000.00 },
            // { ano:2021, valorprazo : 6000.00, valorvista : 7000.00, perprazo: 20, pervista: 15 , valorgeral: 13000.00 },
            // { ano:2020, valorprazo : 5000.00, valorvista : 5000.00, perprazo: 20, pervista: 15 , valorgeral: 10000.00 },
            // { ano:2019, valorprazo : 3000.00, valorvista : 4000.00, perprazo: 20, pervista: 15 , valorgeral: 7000.00 },
            // { ano:2018, valorprazo : 2000.00, valorvista : 3000.00, perprazo: 20, pervista: 15 , valorgeral: 5000.00 },
        ],
        sheet: false,
        carregando: false,
        dados: {},
        busca: {
            dtinicio: `${new Date().getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-01`,
            dtfim:
                `${new Date(new Date().getFullYear(),new Date().getMonth() + 1,0).getFullYear()}-${
                    (new Date( new Date().getFullYear(),new Date().getMonth() + 1,0).getMonth() + 1 < 10 ? "0": "") +(new Date().getMonth() + 1)}-${
                    (new Date( new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).getDate() < 10 ? "0": "") + (new Date().getDate())}`,
            tipopagamento: null,
        },
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
    methods: {
        abrirDialog(dados, header, title) {
            this.dialog.status = true;
            this.dialog.dados = dados;
            this.dialog.header = header;
            this.dialog.title = title;
        },
        get() {
            this.dados = {};
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}dashboard/compras/pedidos`, {
                    // dtinicio: this.busca.dtinicio,
                    // dtfim: this.busca.dtfim,
                    // tipopagamento: this.busca.tipopagamento,
                })
                .then((res) => {
                    this.dadosmes = res.data.pedidoscompraano
                    console.log(this.dadosmes)
                    this.dadosdias = res.data.pedidocomprames
                    this.totais = res.data.pedidocompratotalano
                    this.dados = res.data.lista.map((v) => {
                        return {
                            ...v,
                            flagcancelado : v.flagcancelado == 'T' ? true : false
                        };
                    });
                    this.dados.total = res.data.total
                    this.carregando = false;
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
        async init() {
            await this.get();
        },
    },
    watch: {
        "busca.dtfim": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtinicio = this.busca.dtfim;
            }
        },
        "busca.dtinicio": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtfim = this.busca.dtinicio;
            }
        },
    },
    created() {
        this.init();
    },
};
</script>

<style scoped>

</style>